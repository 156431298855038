import React, { useMemo, useRef } from "react";
import { enableRipple } from "@syncfusion/ej2-base";
import {
  DropDownButtonComponent,
  ItemModel,
} from "@syncfusion/ej2-react-splitbuttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  ColumnDirective,
  ColumnsDirective,
  Grid,
  GridComponent,
  ToolbarItems,
  ExcelExport,
  Inject,
  Toolbar,
  PdfExport,
  Filter,
  Sort,
  FilterSettingsModel,
  excelHeaderQueryCellInfo,
} from "@syncfusion/ej2-react-grids";
// import Select from "react-select";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import moment from "moment";
import { IBaseComponent } from "./types";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "../store";
import Select from "react-select";
import { wbpcb_base64 } from "../util/logo_base64";
enableRipple(true);

export interface IHistoryDatagridComponent extends IBaseComponent {
  rowIndexValue: React.MutableRefObject<number>;
}
export const HistoryDatagrid: React.FC<IHistoryDatagridComponent> = ({
  Devices,
  SelectedDevice,
  setSelectedDevice,
  StartDate,
  setStartDate,
  EndDate,
  setEndDate,
  Data,
  TotalData,
  rowIndexValue,
}) => {
  const gridref = useRef<GridComponent | null>(null);
  const toolbar: ToolbarItems[] = [
    "ExcelExport",
    "PdfExport",
    // "CsvExport",
    "Search",
    // "Print",
  ];
  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };
  const excelHeaderQueryCellInfo = (args: ClickEventArgs) => {
    if (
      (gridref.current?.excelExportModule as any).sheet.images === null ||
      (gridref.current?.excelExportModule as any).sheet.images === undefined
    ) {
      (gridref.current?.excelExportModule as any).sheet.images = [];
    }
    const excelImage: any = {
      image: wbpcb_base64,
      row: 1,
      column: 1,
      lastRow: 2,
      lastColumn: 3,
    };
    (gridref.current?.excelExportModule as any).sheet.images.push(excelImage);
  };
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (gridref.current === null || !args) return;
    switch (args.item.text) {
      case "Excel Export":
        rowIndexValue.current = 0;
        gridref.current.excelExport({
          fileName: `${SelectedDevice?.location},${
            SelectedDevice?.street
          }-${moment(StartDate).format("ll")}-${moment(EndDate).format(
            "ll"
          )}.xlsx`,
          header: {
            headerRows: 7,
            rows: [
              {
                cells: [
                  {
                    colSpan: 12,
                    value: "QUEST MALL",
                    style: {
                      backColor: "#cde6f7",
                      fontColor: "#000000",
                      fontSize: 18,
                      hAlign: "Right",
                      bold: true,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: "QUEST MALL",
                    style: {
                      backColor: "#cde6f7",
                      fontColor: "#000000",
                      fontSize: 16,
                      hAlign: "Right",
                      bold: true,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: `Device: ${
                      DropDownListItems.find(
                        (i) => i.value === SelectedDevice?.dev_id
                      )?.label
                    }`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value:
                      "Parameter: LA(s) |	LC(s) |	LZ(s) |	LA(eqt) |	LC(eqt) |	LZ(eqt) |	LA(peakt) |	LC(peakt) |	LZ(peakt)",
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: `From ${moment(StartDate).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )} To ${moment(EndDate).format("DD/MM/YYYY hh:mm:ss")}`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: `Downloaded on ${moment().format(
                      "DD-MM-YYYY hh:mm:ss A"
                    )}`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: DropDownListItems.find(
                      (i) => i.value === SelectedDevice?.dev_id
                    )?.label,
                    style: {
                      backColor: "#204FAC",
                      fontColor: "#ffffff",
                      fontSize: 13,
                      hAlign: "Center",
                    },
                  },
                ],
              },
            ],
          },
        });
        break;
      case "PDF Export":
        rowIndexValue.current = 0;
        gridref.current.pdfExport({
          pageSize: "A3",
          fileName: `${SelectedDevice?.location},${
            SelectedDevice?.street
          }-${moment(StartDate).format("ll")}-${moment(EndDate).format(
            "ll"
          )}.pdf`,
          pageOrientation: "Landscape",
          theme: {
            header: {
              bold: true,
              // border: { color: '#64FA50' },
              fontColor: "#000000",
              fontName: "Calibri",
              fontSize: 14,
            },
            record: {
              fontColor: "#000000",
              fontName: "Calibri",
              fontSize: 12,
            },
          },
          header: {
            contents: [
              // {
              //   type: "Image",
              //   src: wbpcb_base64,
              //   position: { x: 0, y: 0 },
              //   size: { height: 100, width: 400 },
              // },
              {
                position: { x: 0, y: 100 },
                style: { textBrushColor: "#000000", fontSize: 25 },
                type: "Text",
                value: "CONTINUOUS NOISE MONITORING SYSTEM",
              },
              {
                position: { x: 0, y: 135 },
                style: { textBrushColor: "#000000", fontSize: 18 },
                type: "Text",
                value: `Created at: ${moment().format("DD-MM-YYYY HH:mm:ss")}`,
              },
              {
                position: { x: 300, y: 135 },
                style: { textBrushColor: "#000000", fontSize: 18 },
                type: "Text",
                value: `From ${moment(StartDate).format("DD-MM-YYYY")} To ${moment(EndDate).format("DD-MM-YYYY")}`,
              },
              {
                position: { x: 0, y: 165 },
                style: { textBrushColor: "#000000", fontSize: 18 },
                type: "Text",
                value: `${DropDownListItems.find((i) => i.value === SelectedDevice?.dev_id)
                ?.label}`,
              },
            ],
            fromTop: 0,
            height: 200,
          },
        });
        break;
      case "CSV Export":
        rowIndexValue.current = 0;
        gridref.current.csvExport({
          fileName: `${SelectedDevice?.location},${
            SelectedDevice?.street
          }-${moment(StartDate).format("ll")}-${moment(EndDate).format(
            "ll"
          )}.csv`,
        });
        break;
    }
  };
  const DropDownListItems: any[] = useMemo(
    () =>
      Devices.map((d) => {
        const item: any = {
          label: `${d.location}, ${d.street}`,
          value: d.dev_id,
        };
        return item;
      }),
    [Devices]
  );
  const { state } = useSelector((state: RootState) => state.user);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      // This line disable the blue border

      border: state.isFocused ? "1px solid #acacac" : "1px solid #acacac",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        boxShadow: state.isFocused ? 0 : 0,
      },
    }),
  };
  if (state !== "loggedIn") return <Redirect to="/signin" />;
  return (
    <div className="flex flex-col justify-start items-center w-full">
      <div className="flex flex-col sm:flex-row justify-start items-center w-full gap-8 my-3">
        {/* <DropDownButtonComponent
          className="ml-2"
          id="element"
          items={DropDownListItems}
          select={(e: any) => {
            setSelectedDevice(
              Devices.find((d) => d.dev_id === e.item.properties.id)
            );
          }}
        >
          {typeof SelectedDevice === "undefined"
            ? "Select Device"
            : `${SelectedDevice.location}, ${SelectedDevice.street}`}
        </DropDownButtonComponent> */}
        <Select
          className="basic-single w-full text-black ml-2"
          classNamePrefix="select"
          styles={customStyles}
          isSearchable
          name="color"
          placeholder={
            DropDownListItems.find((i) => i.value === SelectedDevice?.dev_id)
              ?.label
          }
          onChange={(e: any) => {
            setSelectedDevice(Devices.find((d) => d.dev_id === e.value));
          }}
          options={DropDownListItems}
        />
        <div className="w-2/12">
          <DatePickerComponent
            id="datepicker"
            value={StartDate}
            placeholder="Enter Start Date"
            onChange={(e: any) => {
              setStartDate(new Date(e.value));
            }}
            format="dd/MM/yyyy"
          />
        
        </div>
        <div className="w-2/12">
          <DatePickerComponent
            id="datepicker"
            placeholder="Enter End date"
            value={EndDate}
            min={StartDate}
            onChange={(e: any) => {
              setEndDate(new Date(e.value));
            }}
            format="dd/MM/yyyy"
          />
        </div>
        <div className="flex flex-1 justify-end items-center pr-2">
          Showing {TotalData} rows
        </div>
      </div>
      <div
        style={{ height: "75vh", width: "99%" }}
        className="w-screen overflow-y-scroll mb-10 border-2 border-gray-600 ml-5 mr-5"
      >
        <GridComponent
          dataSource={Data}
          ref={(g) => (gridref.current = g)}
          toolbar={toolbar}
          filterSettings={filterOptions}
          toolbarClick={toolbarClick}
          allowExcelExport
          allowPdfExport
          allowFiltering
          allowSorting
          allowTextWrap
          showColumnMenu={false}
          enableAltRow
          dataBound={(e) => {
            rowIndexValue.current = 0;
          }}
          beforeDataBound={(e) => {
            rowIndexValue.current = 0;
          }}
          // @ts-ignore
          excelHeaderQueryCellInfo={excelHeaderQueryCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective field="id" headerText="Sl no." width={110}/>
            <ColumnDirective field="location" headerText="Location" />
            <ColumnDirective
              field="timestamp"
              textAlign="Left"
              headerText="Timestamp"
              width={180}
            />
            <ColumnDirective field="las" textAlign="Left" headerText="LA(s)" />
            <ColumnDirective field="lcs" textAlign="Left" headerText="LC(s)" />
            <ColumnDirective field="lzs" textAlign="Left" headerText="LZ(s)" />
            <ColumnDirective
              field="laeqt"
              textAlign="Left"
              headerText="LA(eqt)"
            />
            <ColumnDirective
              field="lceqt"
              textAlign="Left"
              headerText="LC(eqt)"
            />
            <ColumnDirective
              field="lzeqt"
              textAlign="Left"
              headerText="LZ(eqt)"
            />
            <ColumnDirective
              field="lapeakt"
              textAlign="Left"
              headerText="LA(peakt)"
            />
            <ColumnDirective
              field="lcpeakt"
              textAlign="Left"
              headerText="LC(peakt)"
            />
            <ColumnDirective
              field="lzpeakt"
              textAlign="Left"
              headerText="LZ(peakt)"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};
