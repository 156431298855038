import React, { useCallback, useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { IBaseComponent } from "./types";
import { getDeviceListReturnItemType } from "../api";
import debounce from "lodash.debounce";
import { FaTimesCircle, FaTimes } from "react-icons/fa";
import {
  getNoiseLevelColorCode,
  NoiseLevelZoneType,
} from "../util/noise_color_getter";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { MapMarker } from "../components/mapmarker";
import moment from "moment";
import { getANMSData } from "../api/getANMSData";
import { Redirect } from "react-router-dom";
import { getAliveANMSData } from "../api/getAliveANMSData";

export const MapView: React.FC<IBaseComponent> = ({
  Devices,
  setSelectedDevice,
  SelectedDevice,
}) => {
  const TagItem = useRef<HTMLDivElement | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [MapInstance, setMapInstance] =
    React.useState<google.maps.Map<Element> | null>(null);
  const mapref = useRef<Element | null>(null);
  const [data, setData] = useState<any>();
  const [AliveData, setAliveData] = useState<any>();
  const [SearchQuery, setSearchQuery] = useState<string>("");
  const [FilteredTags, setFilteredTags] =
    useState<getDeviceListReturnItemType[]>(Devices);
  const handleSearch = useCallback(
    debounce(() => {
      if (SearchQuery === "") return Devices;
      setFilteredTags(
        Devices.filter((i) =>
          JSON.stringify(i).toLowerCase().includes(SearchQuery)
        )
      );
    }, 1000),
    [SearchQuery, Devices]
  );
  useEffect(() => {
    setFilteredTags(Devices);
    setSearchQuery("");
  }, [Devices]);

  const getMinNewData = async () => {
    if (!!SelectedDevice?.dev_id) {
      const result = await getANMSData(SelectedDevice?.dev_id);
      setData(result.data.data);
    }
  };
  const getSecNewData = async () => {
    if (!!SelectedDevice?.dev_id) {
    
      const result = await getAliveANMSData(SelectedDevice?.dev_id);
      setAliveData(result.data.data);

    }
  };


  useEffect(() => {
    if (TagItem.current !== null)
      TagItem.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
    if (!!SelectedDevice && !!MapInstance)
      MapInstance.setCenter({
        lat: parseFloat(SelectedDevice.lat),
        lng: parseFloat(SelectedDevice.long),
      });
      const secInterval = setInterval(function fetchData() {
        getSecNewData();
        return fetchData;
      }(),10 * 1000);
      const minInterval = setInterval(function fetchData() {
        getMinNewData();
        return fetchData;
      }(),50 * 1000);
      return () => {
        clearInterval(secInterval);
        clearInterval(minInterval);
      };
  
  }, [SelectedDevice, MapInstance]);
  const { state } = useSelector((state: RootState) => state.user);
  if (state !== "loggedIn") return <Redirect to="/signin" />;
  return (
    <div className="flex flex-1 flex-row max-h-screen overflow-hidden">
      {/* <div className="bg-gray-800 h-auto">
        <div className="flex flex-row justify-start items-center absolute top-20 left-2 z-10 w-3/12 ring-1">
          <div className="p-2 bg-gray-800 text-white font-semibold px-4 flex flex-row justify-end items-center">
            Search
          </div>
          {SearchQuery !== "" && (
            <span className="absolute left-24">
              <FaTimesCircle
                size="20px"
                className="text-gray-900"
                onClick={(e) => {
                  setSearchQuery("");
                  handleSearch();
                  setFilteredTags(Devices);
                }}
              />
            </span>
          )}
          <input
            type="text"
            name="searchDevice"
            id="searchDevice"
            className="flex-1 p-2 -r-md outline-none focus:bg-yellow-100 pl-12"
            value={SearchQuery}
            onChange={(e) => {
              console.log(e.target.value);
              setSearchQuery(e.target.value);
              handleSearch();
            }}
          />
        </div>
        <div
          className="gap-2 absolute top-32 left-2 z-10 flex flex-col justify-start items-stretch overflow-auto pr-2 w-3/12 bg-gray-700 p-2 border-2"
          style={{
            maxHeight: "75vh",
            overflowX: "hidden",
          }}
        >
          {FilteredTags.length > 0
            ? FilteredTags.map((d) => (
                <div
                  className={`flex flex-col justify-start items-stretch p-4 z-20 cursor-pointer ${
                    SelectedDevice?.dev_id === d.dev_id
                      ? "bg-gray-600"
                      : "bg-gray-800"
                  }`}
                  onClick={() => {
                    setSelectedDevice(d);
                  }}
                  ref={(r) => {
                    if (SelectedDevice?.dev_id === d.dev_id)
                      TagItem.current = r;
                  }}
                >
                  <div className="flex flex-row justify-between items-center text-gray-200 p-4 py-1 text-md">
                    <div className="rounded-full px-2 py-1 font-semibold text-xs text-gray-700 bg-gray-100">
                      {d.zone}
                    </div>
                     <div
                      className="rounded-full w-5 h-5"
                      style={{
                        backgroundColor: getNoiseLevelColorCode(
                          parseFloat(d.laeqt),
                          d.zone as NoiseLevelZoneType
                        ),
                      }}
                    ></div> 
                  </div>
                  <div className="flex flex-row justify-start  text-gray-200 p-4 py-1 text-sm">
                    <div className="text-gray-50 text-sm font-bold">
                      {d.location}
                    </div>
                  </div>
                   <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-sm">
                    <div>Device Id: </div>
                    <div
                      className="text-gray-50 text-sm font-bold px-4"
                      style={{ textOverflow: "ellipsis" }}
                    >
                      {d.dev_id}
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div> */}
      <div>
      <GoogleMapReact
       style={{ flex: 1, width: "100vw", height: "100%" }}
        bootstrapURLKeys={{ key: "AIzaSyCxB4NOyF5Du4Ih88qXosmZHq4HVCAG_yk" }}
        defaultCenter={{ lat: 22.562166, lng: 88.409355 }}
        defaultZoom={14}
        resetBoundsOnResize
        options={{
          fullscreenControl: false,
          zoomControl: false,
        }}
        onGoogleApiLoaded={({ map, maps, ref }) => {
          mapref.current = ref;
          setMapInstance(map);
        }}
      >
        {Devices.map((d) => (
          <MapMarker
            lat={parseFloat(d.lat)}
            lng={parseFloat(d.long)}
            key={d.id}
            // data={d}
            data={SelectedDevice?.dev_id === d.dev_id ? {
              ...d,
              laeqt: data?.laeqt ?? d.laeqt,
            } : d}

            text={d.location}
            selected={d.dev_id === SelectedDevice?.dev_id}
            onClick={() => {
              setSelectedDevice(d);
            }}
          />
        ))}
      </GoogleMapReact>
      </div>
      {!!SelectedDevice ? (
        <div
          style={{
            position: "absolute",
            right: 10,
            maxHeight: "85vh",
            overflowX: "hidden",
          }}
          className="top-20 bg-gray-700 "
        >
          <span className="fixed right-8 py-3 cursor-pointer">
            <FaTimes
              size="15px"
              className="text-white"
              onClick={(e) => {
                setSelectedDevice(undefined);
              }}
            />
          </span>
          <div
            style={{ minHeight: 340, width: 450 }}
            className={`p-2 h-3/6 border-2 border-gray-600  flex flex-col justify-start items-stretch shadow-md bg-gray-700 `}
          >
            <div className="flex flex-row justify-start items-center text-gray-200 p-4 text-2xl">
              <div>Noise Level </div>
              <div className="text-gray-50 text-2xl px-4">
                {data?.laeqt ?? SelectedDevice.laeqt} db(A)
              </div>
              {/* <div
                className="rounded-full w-8 h-8"
                style={{
                  backgroundColor: getNoiseLevelColorCode(
                    parseFloat(SelectedDevice.laeqt),
                    //@ts-ignore
                    SelectedDevice.zone
                  ),
                }}
              ></div> */}
            </div>
            <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-sm">
              <div>Device Location </div>
              <div className="text-gray-50 text-l font-bold px-4">
                {SelectedDevice.location}
              </div>
            </div>
            <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-sm">
              <div>Device Zone </div>
              <div className="text-gray-50 text-l font-bold px-4">
                {SelectedDevice.zone}
              </div>
            </div>
            {/* <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-l">
              <div>Device ID: </div>
              <div className="text-gray-50 text-l font-bold px-4">
                {SelectedDevice.dev_id}
              </div>
            </div> */}
            <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-sm">
              <div>District </div>
              <div className="text-gray-50 text-l font-bold px-5">
                {SelectedDevice.district}
              </div>
            </div>
            <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-sm">
              <div>Timestamp </div>
              <div className="text-gray-50 text-l font-bold px-5">
                {moment(data?.created_at).format("MMMM Do YYYY, h:mm a")}
              </div>
            </div>
            <div className="flex flex-row justify-center items-center m-2">
              <table className="bg-gray-700 w-full table">
                <thead className="table-header-group">
                  <tr className="table-row">
                    <th className="p-2 text-white bg-gray-700 mx-1 text-left">
                      Parameter
                    </th>
                    <th className="p-2 text-white bg-gray-700 mx-1 text-left">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="table-row-group p-2 text-gray-200">
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LAs</td>
                    <td className="p-2">{AliveData?.las}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LCs</td>
                    <td className="p-2">{AliveData?.lcs}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LZs</td>
                    <td className="p-2">{AliveData?.lzs}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LAeqt</td>
                    <td className="p-2">{data?.laeqt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LCeqt</td>
                    <td className="p-2">{data?.lceqt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LZeqt</td>
                    <td className="p-2">{data?.lceqt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LApeakt</td>
                    <td className="p-2">{data?.lapeakt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LCpeakt</td>
                    <td className="p-2">{data?.lcpeakt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LZpeakt</td>
                    <td className="p-2">{data?.lzpeakt}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MapView;
