import React, { useCallback, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GraphReport, HistoryDatagrid } from "./pages";
import { Header } from "./components";
import {
  getDeviceList,
  getDeviceListReturnItemType,
  getDeviceListReturnType,
  getHistoricalData,
  getHistoricalDataReturnItemType,
  getHistoricalDataReturnType,
} from "./api";
import { IBaseComponent } from "./pages/types";
import { AxiosResponse } from "axios";
import moment from "moment";
import MapView from "./pages/mapview";
import { SignIn } from "./pages/signin";
import { getAliveHistoricalData } from "./api/getAliveHistoricalData";

export const App: React.FC = () => {
  const [Devices, setDevices] = useState<getDeviceListReturnItemType[]>([]);
  const [SelectedDevice, setSelectedDevice] =
    useState<getDeviceListReturnItemType>();
  const [StartDate, setStartDate] = useState<Date>(new Date());
  const [animate, setanimate] = useState("Overlay");
  const [EndDate, setEndDate] = useState<Date>(new Date());
  const [Data, setData] = useState<getHistoricalDataReturnItemType[]>([]);
  const [AliveData, setAliveData] = useState<any[]>([]);
  const [TotalData, setTotalData] = useState(0);
  const [SelectedDataParameter, setSelectedDataParameter] = useState<string>();
  const loadDevices = useCallback(async () => {
    // enqueueSnackbar(`Fetching Device List.`, {
    //   variant: "info",
    // });
    const res: AxiosResponse<getDeviceListReturnType> = await getDeviceList();
    console.log({ res });
    if (typeof res?.data === "undefined") {
      // enqueueSnackbar(`Failed to fetch device list.`, {
      //   variant: "error",
      // });
      setDevices([]);
    } else {
      // enqueueSnackbar(
      //   `Device list fetched. ${res.data.data.length} devices fetched.`,
      //   {
      //     variant: "success",
      //   }
      // );
      const response_data: getDeviceListReturnType = res.data;
      const filteredDevices = response_data.data.filter(
        (d) => d.id === 10
      );
      setDevices(filteredDevices);
      setSelectedDevice(filteredDevices[0]);
    }
  }, []);
  useEffect(() => {
    loadDevices();
  }, []);
  let rowIndexValue = useRef<number>(0);
  useEffect(() => {
    const getHistoryData = async () => {
      // enqueueSnackbar(`Fetching Historic ANMS data.`, {
      //   variant: "info",
      // });
      const res: AxiosResponse<getHistoricalDataReturnType> =
        await getHistoricalData({
          dev_id: SelectedDevice?.dev_id || "",
          start_time: moment(StartDate).format("YYYY-MM-DD") + " 00:00:00",
          end_time: moment(EndDate).format("YYYY-MM-DD") + " 23:59:59",
        });
        const res2 = await getAliveHistoricalData({
          dev_id: SelectedDevice?.dev_id || "",
          start_time: moment(StartDate).format("YYYY-MM-DD") + " 00:00:00",
          end_time: moment(EndDate).format("YYYY-MM-DD") + " 23:59:59",
        });

      // console.log({ res });
      if (typeof res?.data === "undefined") {
        // enqueueSnackbar(`Failed to fetch latest Historic ANMS data.`, {
        //   variant: "error",
        // });
        setData([]);
        setAliveData([]);
        rowIndexValue.current = 0;
        setTotalData(0);
      } else {
        // enqueueSnackbar(
        //   `Latest Historic ANMS data fetched. ${res.data.total} rows fetched.`,
        //   {
        //     variant: "success",
        //   }
        // );
        const response_data: getHistoricalDataReturnType = res.data;
        const response_data2 = res2.data;
        setData(
          response_data.data.map((i, index) => ({
            ...i,
            timestamp: moment(i.timestamp).format("lll"),
            id: index + 1,
            location: SelectedDevice ? SelectedDevice.location : "N/A",
          }))
        );
        setAliveData(
          response_data2.data.map((i:any, index:number) => ({
            ...i,
            timestamp: moment(i.timestamp).format("lll"),
            id: index + 1,
            location: SelectedDevice ? SelectedDevice.location : "N/A",
          }))
        );

        setSelectedDataParameter("laeqt");
        setTotalData(response_data.total);
      }
    };
    rowIndexValue.current = 0;
    console.log("Resetting rowIndexValue");
    getHistoryData();
  }, [SelectedDevice, StartDate, EndDate]);
  const PropsToPass: IBaseComponent = {
    AliveData,
    Data,
    setData,
    Devices,
    setDevices,
    StartDate,
    setStartDate,
    EndDate,
    setEndDate,
    SelectedDevice,
    setSelectedDevice,
    TotalData,
    setTotalData,
  };

  if (typeof window !== "undefined") {
    window.addEventListener("keydown", (e: any) => {
      if (e.keyCode === 32 || e.keyCode === 13) {
        setanimate("animating");
      }
    });
  }

  return (
    <Router>
      <div className="flex-1 flex flex-col w-full items-stretch h-screen max-h-screen overflow-hidden">
        {/* <div className={`${animate}`}>
          <div className="flex flex-col">
            <div className="flex flex-row justify-center">
              <p className="flex flex-col absolute bottom-96 border-indigo-700  bg-white pt-4 pb-4 pl-4 pr-4 shadow-xl">
                <span className="flex flex-row justify-center">
                  <img
                    src="WBPCB_fulllogo.png"
                    alt="WBPCB Logo"
                    height={95}
                    width={400}
                  />
                </span>
                <span className="text-xl text-black font-bold text-center self-center w-max pt-5">
                  CONTINUOUS NOISE MONITORING SYSTEM
                </span>
                <span className="text-xl font-bold text-black pt-2 self-center">
                  28th December, 2021
                </span>
              </p>
            </div>
            <div
              className="bn632-hover bn18 self-center absolute bottom-56"
              onClick={() => {
                setanimate("animating");
              }}
            >
              <p className="pt-4 text-xl font-bold">LAUNCH</p>
            </div>
            <p className="text-white text-lg font-semibold self-center text-center absolute bottom-3">
              wbpcb.gov.in
            </p>
          </div>
        </div> */}
        <div
        style={{ backgroundColor: "#2259AC" }}
        className="absolute bottom-0 z-30 font-semibold text-white text-xs pl-1 pr-1 pt-1 pb-1"
      >
        <a href="https://www.wtl.co.in/" target={"_blank"} rel="noreferrer">
          Powered by Webel Technology Limited
        </a>
      </div>
        <Header />
        <div className="flex flex-1 flex-col box-border">
          <Switch>
            <Route exact path="/signin">
              <SignIn {...PropsToPass} />
            </Route>
            <Route exact path="/graph">
              <GraphReport
                {...PropsToPass}
                setSelectedDataParam={setSelectedDataParameter}
                SelectedDataParam={SelectedDataParameter}
              />
            </Route>
            <Route exact path="/map">
              <MapView {...PropsToPass} />
            </Route>
            <Route exact path="/history">
              <HistoryDatagrid {...PropsToPass} rowIndexValue={rowIndexValue} />
            </Route>
            <Route exact path="/">
              <MapView {...PropsToPass} />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
};
